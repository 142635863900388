export type RouteName =
  | `blog`
  | `brand`
  | `carta`
  | `cartaCapTable`
  | `cartaCareers`
  | `cartaPress`
  | `caseStudies`
  | `caseStudiesCarta`
  | `caseStudiesEquipmentshareCartaCross`
  | `contactUs`
  | `contentCenter`
  | `contentCenterTags`
  | `contentCenterTagsCaseStudy`
  | `documents`
  | `documentsBuyersGuide`
  | `documentsCcmxAuditReport123120`
  | `documentsCcmxBusinessContinuityPlanDisclosureStatement`
  | `documentsCcmxCashManagementAndSweepProgramDisclosures`
  | `documentsCcmxCustomerComplaintsDisclosureStatement`
  | `documentsCcmxCustomerRelationshipSummary`
  | `documentsCcmxESignAndElectronicDeliveryConsent`
  | `documentsCcmxFinraPublicDisclosureProgram`
  | `documentsCcmxFundsAvailabilityDisclosure`
  | `documentsCcmxPermittedClientNoticeForCanadianInvestors`
  | `documentsCcmxPrivacyPolicy`
  | `documentsCcmxUsaPatriotActNotice`
  | `documentsCcmxUseOfServicesAndRiskDisclosureStatement`
  | `documentsInvestorOnboardingChecklist`
  | `documentsNewIssuerListingChecklist`
  | `documentsSellersGuide`
  | `documentsTaxGuideForSellers`
  | `documentsTenderOfferBuyersGuide`
  | `documentsTenderOfferSellersGuide`
  | `emailInfo`
  | `facebook`
  | `finra`
  | `finraBrokerCheck`
  | `finraMember`
  | `glossary`
  | `home`
  | `institutionalInvestors`
  | `institutionalInvestorsRequestInformation`
  | `investorApplicationDocuments`
  | `investorApplicationDocumentsBrokerageAccountAgreement`
  | `investorApplicationDocumentsCcmxLegalEntityInvestorApplicationForm`
  | `investorApplicationDocumentsCertificateOfAuthority`
  | `investorApplicationDocumentsIndividualAccreditedInvestorQuestionnaire`
  | `investorApplicationDocumentsQibQuestionnaire`
  | `investorApplicationDocumentsSection2OfInvestorApplicationRollupAccounts`
  | `investorApplicationDocumentsSection3OfInvestorApplicationLegalEntities`
  | `investorApplicationDocumentsSection4OfInvestorApplicationAuthorizedPersons`
  | `investorApplicationDocumentsSection5OfInvestorApplicationIndividualControlPersonsAndEntityOwners`
  | `investorApplicationDocumentsSection6OfInvestorApplicationReadOnlyAccountUsers`
  | `investorApplicationDocumentsSuitabilityAttestation`
  | `investorApplicationDocumentsThirdPartyAgentAuthorization`
  | `linkedIn`
  | `logoKit`
  | `origin`
  | `partnersAcademic`
  | `partnersLegal`
  | `privateCompanies`
  | `privateCompaniesCartaCross`
  | `privateCompaniesRequestInformation`
  | `regulatoryDisclosures`
  | `requestInformation`
  | `sipc`
  | `terms`
  | `twitter`

const routes: { [key in RouteName]: string } = {
  blog: `/blog`,
  brand: `/brand`,
  carta: `https://carta.com`,
  cartaCapTable: `https://carta.com/cap-table/`,
  cartaCareers: `https://carta.com/careers/`,
  cartaPress: `https://carta.com/press/`,
  caseStudies: `/case-studies`,
  caseStudiesCarta: `/case-studies/carta`,
  caseStudiesEquipmentshareCartaCross: `/case-studies/equipmentshare-carta-cross`,
  contactUs: `/contact-us`,
  contentCenter: `/content-center`,
  contentCenterTags: `/content-center\#tags`,
  contentCenterTagsCaseStudy: `/content-center/tags/case-study`,
  documents: `/documents`,
  documentsBuyersGuide: `/documents/CartaX Buyers Guide.pdf`,
  documentsCcmxAuditReport123120: `/documents/CCMX Audit Report (12-31-20).pdf`,
  documentsCcmxBusinessContinuityPlanDisclosureStatement: `/documents/CCMX Business Continuity Plan Disclosure Statement.pdf`,
  documentsCcmxCashManagementAndSweepProgramDisclosures: `/documents/CCMX Cash Management and Sweep Disclosures.pdf`,
  documentsCcmxCustomerComplaintsDisclosureStatement: `/documents/CCMX Customer Complaints Disclosure Statement.pdf`,
  documentsCcmxCustomerRelationshipSummary: `/documents/CCMX Customer Relationship Summary.pdf`,
  documentsCcmxESignAndElectronicDeliveryConsent: `/documents/CCMX E-Sign and Electronic Delivery Consent.pdf`,
  documentsCcmxFinraPublicDisclosureProgram: `/documents/CCMX FINRA Public Disclosure Program.pdf`,
  documentsCcmxFundsAvailabilityDisclosure: `/documents/CCMX Funds Availability Disclosure.pdf`,
  documentsCcmxPermittedClientNoticeForCanadianInvestors: `/documents/CCMX Permitted Client Notice for Canadian Investors.pdf`,
  documentsCcmxPrivacyPolicy: `/documents/CCMX Privacy Policy.pdf`,
  documentsCcmxUsaPatriotActNotice: `/documents/CCMX USA PATRIOT Act Notice.pdf`,
  documentsCcmxUseOfServicesAndRiskDisclosureStatement: `/documents/CCMX Use of Services and Risk Disclosure Statement.pdf`,
  documentsInvestorOnboardingChecklist: `/documents/Investor Onboarding Checklist.pdf`,
  documentsNewIssuerListingChecklist: `/documents/New Issuer Listing Checklist.pdf`,
  documentsSellersGuide: `/documents/CartaX Sellers Guide.pdf`,
  documentsTaxGuideForSellers: `/documents/CartaX Tax Guide for Sellers.pdf`,
  documentsTenderOfferBuyersGuide: `/documents/CartaX Tender Offer Buyers Guide.pdf`,
  documentsTenderOfferSellersGuide: `/documents/CartaX Tender Offer Sellers Guide.pdf`,
  emailInfo: `mailto:info@cartacapitalmarkets.com`,
  facebook: `https://www.facebook.com/cartahq`,
  finra: `https://www.finra.org`,
  finraBrokerCheck: `https://brokercheck.finra.org/firm/summary/304751`,
  finraMember: `https://files.brokercheck.finra.org/firm/firm_304751.pdf`,
  glossary: `/glossary`,
  home: `/`,
  institutionalInvestors: `/institutional-investors`,
  institutionalInvestorsRequestInformation: `/institutional-investors/request-information`,
  investorApplicationDocuments: `/investor-application-documents`,
  investorApplicationDocumentsBrokerageAccountAgreement: `/investor-application-documents/Brokerage Account Agreement.pdf`,
  investorApplicationDocumentsCcmxLegalEntityInvestorApplicationForm: `/investor-application-documents/CCMX Legal Entity Investor Application Form.pdf`,
  investorApplicationDocumentsCertificateOfAuthority: `/investor-application-documents/Certificate of Authority.pdf`,
  investorApplicationDocumentsIndividualAccreditedInvestorQuestionnaire: `/investor-application-documents/Individual Accredited Investor Questionnaire.pdf`,
  investorApplicationDocumentsQibQuestionnaire: `/investor-application-documents/QIB Questionnaire.pdf`,
  investorApplicationDocumentsSection2OfInvestorApplicationRollupAccounts: `/investor-application-documents/Section 2 of Investor Application - Rollup Accounts.pdf`,
  investorApplicationDocumentsSection3OfInvestorApplicationLegalEntities: `/investor-application-documents/Section 3 of Investor Application - Legal Entities.pdf`,
  investorApplicationDocumentsSection4OfInvestorApplicationAuthorizedPersons: `/investor-application-documents/Section 4 of Investor Application - Authorized Persons.pdf`,
  investorApplicationDocumentsSection5OfInvestorApplicationIndividualControlPersonsAndEntityOwners: `/investor-application-documents/Section 5 of Investor Application - Individual Control Persons and Entity Owners.pdf`,
  investorApplicationDocumentsSection6OfInvestorApplicationReadOnlyAccountUsers: `/investor-application-documents/Section 6 of Investor Application - Read Only Account Users.pdf`,
  investorApplicationDocumentsSuitabilityAttestation: `/investor-application-documents/Suitability Attestation.pdf`,
  investorApplicationDocumentsThirdPartyAgentAuthorization: `/investor-application-documents/Third Party Agent Authorization.pdf`,
  linkedIn: `https://www.linkedin.com/company/carta--`,
  logoKit: `/brand/CartaX logo kit.zip`,
  origin: `https://cartax.com`,
  partnersAcademic: `/partners/academic`,
  partnersLegal: `/partners/legal`,
  privateCompanies: `/private-companies`,
  privateCompaniesCartaCross: `/private-companies/carta-cross`,
  privateCompaniesRequestInformation: `/private-companies/request-information`,
  regulatoryDisclosures: `/regulatory-disclosures`,
  requestInformation: `/request-information`,
  sipc: `https://www.sipc.org`,
  terms: `/terms`,
  twitter: `https://twitter.com/cartainc`,
}

export default routes
