/** @jsx jsx */

import { FC, useEffect } from 'react'

import { RouteName } from '../constants/routes'

interface RedirectProps {
  to: RouteName | string
}

const Redirect: FC<RedirectProps> = ({ to }) => {
  useEffect(() => {
    if (typeof window !== `undefined`) {
      window.location.href = to
    }
  }, [])

  return null
}

export default Redirect
